* {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}
a:hover {
  text-decoration: none !important;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

/* Theme Default */
.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-top-75 {
  padding-top: 75px;
}

.padding-top-80 {
  padding-top: 80px;
}

.padding-top-90 {
  padding-top: 90px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-105 {
  padding-top: 105px;
}

.padding-top-110 {
  padding-top: 110px;
}

.padding-top-115 {
  padding-top: 115px;
}

.padding-top-120 {
  padding-top: 120px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.padding-bottom-65 {
  padding-bottom: 65px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.padding-bottom-90 {
  padding-bottom: 90px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-bottom-105 {
  padding-bottom: 105px;
}

.padding-bottom-110 {
  padding-bottom: 110px;
}

.padding-bottom-115 {
  padding-bottom: 115px;
}

.padding-bottom-120 {
  padding-bottom: 120px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-top-90 {
  margin-top: 90px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-bottom-90 {
  margin-bottom: 90px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

/* Header + Navbar */
.top-height {
  height: 30px;
  padding-top: 5px;
  background-color: #000000;
}

.fs-13 {
  font-size: 13px;
}

.social-icons {
  list-style-type: none;
  text-decoration: none;
  color: white;
}

.social-icons:hover {
  background-color: white;
  color: #000000;
}

.btn-login {
  color: white;
  list-style-type: none;
  text-decoration: none;
  border-radius: 0px;
}

.btn-login:hover {
  list-style-type: none;
  text-decoration: none;
}

.btn-admin-login {
  color: white;
  text-decoration: none;
  border: none;
  padding: 0;
  margin: 0;
}

.btn-admin-login:hover {
  text-decoration: underline;
  background-color: #000000;
  color: white;
  padding: 0 5px;
  border-radius: 0;
}

.btn-admin-login.focus,
.btn-admin-login:focus {
  outline: 0;
  box-shadow: none;
  text-decoration: underline;
  background-color: white;
  color: #000000;
  padding: 0 5px;
  border-radius: 0;
}

/* #navbarNav ul li {
  /* padding: 0px 4px;}  */
#navbarNav ul li:hover {
  text-decoration: underline;
}

/* Header + Navbar */

/* Slider */
.slider-height {
  height: calc(100vh - 106px);
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 25px;
  height: 25px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #004b9c;
  background-clip: padding-box;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  border-radius: 60px;
}

.banner {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  text-align: center;
}

.carousel-caption {
  position: absolute;
  right: 25%;
  bottom: 60px;
  left: 25%;
  z-index: 10;
  color: #ffffff;
  text-align: center;
}

/* Slider */

/* My Courses */
.course-heading {
  letter-spacing: 5px;
}

.my-course {
  position: relative;
  max-width: 230px;
  padding: 0px 5px;
}

.card .card-img-top:hover {
  opacity: 0.5;
}

.card-link {
  list-style-type: none;
  text-decoration: none;
  color: black;
}

/* My Courses */

/* Footer */
.footer {
  height: 40px;
  background-color: #000000;
}

/* Footer */

/* All courses page */
.allCourseHero {
  background: url("./images/allCourses/hero.jpg") center/cover no-repeat;
  position: relative;
  height: calc(100vh - 372px);
}

.course-banner {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #ffffff;
}

/* All courses page */

/* My Course Page */
.myCourseHero {
  background: url("./images/allCourses/hero1.jpg") center/cover no-repeat;
  position: relative;
  height: calc(100vh - 372px);
}

.single-course-title {
  background-color: #000000;
  color: white;
}

/* My Course Page */

/* Profile page */
.profile {
  margin-right: 15px;
  height: calc(100vh - 146px);
}

.marginx {
  margin: 0px 30px;
  min-height: calc(100vh - 146px);
}

.leftbar {
  width: 260px;
  background-color: #0652dd;
  height: calc(100vh - 146px);
  overflow-y: auto;
}

.rightbar {
  width: calc(100% - 260px);
  height: calc(100vh - 146px);
  overflow-y: auto;
}

.profile-sidebar-link {
  min-height: 70px;
  padding-top: 1.5rem;
  padding-left: 22px;
  color: white;
  border-bottom: 1px solid white;
}

.profile-sidebar-active {
  color: black;
  background-color: white;
}

.profile-sidebar-link:hover {
  color: black;
  background-color: white;
  text-decoration: underline;
}

/* Profile page */

/* Assignment Hero */
.assignmentHero {
  background: url("./images/allCourses/hero3.jpg") center/cover no-repeat;
  position: relative;
  height: calc(100vh - 372px);
}

.courseAssignmentHero {
  background: url("./images/allCourses/hero3.jpg") center/cover no-repeat;
  position: relative;
  height: calc(100vh - 325px);
}

.class-card {
  min-height: 200px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 0px 1px 1px rgba(189, 176, 189, 1);
}

.class-card:hover {
  box-shadow: 0px 0px 3px 3px rgba(189, 176, 189, 1);
}

.assignmentCourse-banner {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  position: absolute;
  right: 10%;
  left: 10%;
  top: 10%;
  color: #ffffff;
}

/* Assignment Hero */
/* Forum Page */
.forumHero {
  background: url("./images/allCourses/hero2.jpg") center/cover no-repeat;
  /* url("./images/allCourses/hero4.jpg") center/cover no-repeat; */
  position: relative;
  height: calc(100vh - 372px);
}

.courseForumHero {
  background: url("./images/allCourses/hero2.jpg") center/cover no-repeat;
  position: relative;
  height: calc(100vh - 325px);
}

/* Forum Page */

/* Specific Course page */
.course-title {
  color: #ffffff;
  height: 30px;
  padding-top: 3px;
  padding-left: 15px;
}

.course {
  margin-right: 15px;
  height: calc(100vh - 176px);
}

.coursebar {
  width: 350px;
  background-color: #0652dd;
  height: calc(100vh - 176px);
  overflow-y: auto;
}

.mobileCoursebar {
  background-color: #0652dd;
}

.videobar {
  width: calc(100% - 350px);
  height: calc(100vh - 176px);
  overflow-y: scroll;
}

.video-card-header:hover {
  background-color: #000000;
}

.video-card-header {
  border-bottom: 1px solid white;
}

/* Specific Course page */

/* Login page */
.login-card {
  min-height: calc(100vh - 242px);
}

/* Login page */

/* Admin Page */
.mb-0 > a {
  display: block;
  position: relative;
  text-decoration: none;
  font-size: 18px;
  color: white;
}

.mb-0 > a:after {
  content: "\f078";
  /* fa-chevron-down */
  font-size: 12px;
  font-family: "FontAwesome";
  position: absolute;
  right: 0;
}

.mb-0 > a[aria-expanded="true"]:after {
  content: "\f077";
  /* fa-chevron-up */
}

.admin-card-header {
  background-color: #0652dd;
  color: white;
}

.admin-card-header:hover {
  background-color: #000000;
}

/* Admin Page */

/* Login page */
.login-page {
  overflow-y: auto;
  height: calc(100vh - 146px);
}

/* Admin Dashboard */
.dashboard-card {
  min-height: 150px;
  min-width: 280px;
}

/* Admin Dashboard */

.fullPageHeight {
  height: calc(100vh - 170px);
}

/* video page css */
.disable-link {
  pointer-events: none;
}

.disable-cursor {
  cursor: not-allowed;
}

.assignment-modal {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0;
  outline: 0;
}

.sidebar-link {
  min-height: 70px;
  padding-top: 1.5rem;
  padding-left: 22px;
  color: #ffffff;
  border-bottom: 1px solid white;
}

.sidebar-active {
  color: black;
}

.sidebar-link:hover {
  color: black;
}

/* video page css */

/* Slider Image css */
.slider-delete:hover {
  color: white;
  background-color: red;
  padding: 0px 5px;
}

/* Delete Comment */
.delete-comment {
  color: red;
  border-radius: 2px;
}

.delete-comment:hover {
  font-size: 20px;
  background-color: red;
  color: white;
  border-radius: 2px;
}

/* Delete Comment */

/* No searched course found css */
.no-searched-course {
  min-height: 210px;
}

/* No searched course found css */

/* Shanto vai did these css */
.jodit_tooltip {
  padding: 0 !important;
}

.jodit_tooltip.jodit_tooltip_visible {
  padding: 6px !important;
}

.avatar {
  height: 80px;
  width: 80px;
}

.avatar-sm {
  height: 60px;
  width: 60px;
}

/* Shanto vai did these css */

/* Bootstrap override */
.admin-login-links {
  z-index: 1000000;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #007bff;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
}

.sticky-top {
  box-shadow: 0px 1px 0px 0px rgba(189, 176, 189, 1);
}

/* Bootstrap override */

/* data-table search css */
#myTable_filter > label > input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

#myTableAll_filter > label > input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

#myTableM_filter > label > input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

#myTableAll2_filter > label > input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

/* data-table search css */
.react-datepicker-wrapper {
  width: 75%;
}

/* header top registration link */

@media (max-width: 600px) {
  .fixed-width-registration {
    width: 150px;
    text-align: center;
  }

  .top-height .social-icons {
    display: none;
  }

  .slider-height {
    height: auto !important;
  }

  h5.heading-center {
    text-align: center;
    margin: 0 10px;
    display: block;
    width: 100%;
  }

  .step1,
  .step2,
  .step3 {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .gender {
    max-width: 100% !important;
    margin-bottom: 10px !important;
  }

  .dob {
    max-width: 100% !important;
  }
}

@media (max-width: 400px) {
  .top-height .btn-admin-login:not(.fixed-width-registration) {
    display: none;
  }
}

.custom-lang {
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #1ab394;
  color: white;
  border: none;
  padding: 8px;
  z-index: 10;
}

.toast-notification {
  text-align: center;
}

/* single-notice-box2 */
.single-notice-box2 {
  /* border: 1px solid #007bff; */
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 30px;
  border-radius: 5px;
  overflow: hidden;
}

.noticeIconBox {
  width: 120px;
  height: 120px;
  line-height: 120px;
  background-color: #ffde00;
  display: block;
  border-radius: 50%;
  text-align: center;
}

.noticeIconBox i {
  font-size: 36px;
  color: #fff;
}

.notice-title {
  /* color: #6599FF; */
  color: #212529;
  font-weight: 700;
}

.posImg {
  width: 190px;
  position: absolute;
  top: -4px;
  right: -40px;
  transform: rotate(242deg);
}

/* NoticeHero */
.noticeHero {
  background-image: url("../src//images/allCourses/hero5.jpg") !important;
  height: 150px;
}

/* v2 hero area */
.v2_hero-section {
  padding-top: 170px;
  padding-bottom: 230px;
}

.v2_icons-box {
  background-color: rgb(254, 254, 254);
  box-shadow: 0px 5px 10px 0px rgba(39, 53, 160, 0.07);
}

.social-item {
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  transition: 0.5s;
  color: #233d63;
}

.social-item:hover {
  background: #ff5a2d;
  color: #fff;
}

.icons-title {
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  font-size: 16px;
}

.icons-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: 0;
  background-image: url("../src/images/allCourses/hero-vec.png");
  z-index: 222;
  width: 86px;
  height: 2px;
}

.v2_hero-title {
  display: inline-block;
  margin-top: 40px;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.2;
}

.v2_hero-title .colored-title {
  color: #ff5a2d;
}

.v2_email-box {
  margin-top: 60px;
  position: relative;
}

.emailVec {
  width: 80px;
  position: absolute;
  top: 0;
  left: -56px;
}

.v2_email-email {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px 0px rgba(39, 53, 160, 0.07);
  padding: 20px 30px;
  border: none;
  outline: none;
  width: 75%;
}

.v2_email-email:focus {
  border: none;
  outline: none;
}

.v2_email-btn {
  background: #ff5a2d;
  color: #fff;
  border: none;
  padding: 20px 30px;
  outline: none;
  width: 25%;
}

.vec-item1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.vec-item2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.vec-item3 {
  position: absolute;
  top: 21%;
  left: 0%;
}

.vec-item4 {
  position: absolute;
  top: 10%;
  left: 16%;
}

/* v2_promote-section */

.v2_promote-section {
  margin-top: -60px;
  padding: 50px 40px;
  position: relative;
  border-radius: 30px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  background: #fff;
}

.v2_promote-section::after {
  content: "";
  background-image: url("../src/images/allCourses/promobg.png");
  height: 100%;
  width: 100%;
  position: absolute;
  top: 20%;
  left: 0%;
  z-index: -1;
  transform: rotate(180deg);
}

/* v2_single-card */
.v2_course-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.v2_single-card {
  min-height: 300px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 30px 0px rgba(39, 53, 160, 0.07);
  border-radius: 30px;
}

.v2_single-card a {
  color: #212529;
  text-decoration: none;
  transition: 0.5s;
}

.v2_single-card:hover a {
  color: #6a62ed;
}

/* single-enroll */
.single-enroll {
  border: 1px solid #6a62ed;
  padding: 10px;
  border-radius: 20px;
  transition: 0.5s;
}

.single-enroll:hover {
  border: 1px solid transparent;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 30px 0px rgba(39, 53, 160, 0.07);
}

.single-enroll a {
  color: #212529;
  text-decoration: none;
  transition: 0.5s;
}

.single-enroll:hover a {
  color: #6a62ed;
}

.pos-hanvec {
  position: absolute;
  bottom: -11px;
  right: 59px;
}

.item-animateOne {
  -webkit-animation: animateOne 15s infinite ease-in-out;
  -o-animation: animateOne 15s infinite ease-in-out;
  -ms-animation: animateOne 15s infinite ease-in-out;
  -moz-animation: animateOne 15s infinite ease-in-out;
  animation: animateOne 15s infinite ease-in-out;
}

/* slider styles */
.slick-dots {
  list-style: none !important;
  display: flex !important;
  justify-content: center;
  margin-top: 50px;
}

.slick-dots button {
  width: 10px;
  height: 10px;
  font-size: 0;
  border-radius: 50%;
  display: block;
  line-height: 10px;
  padding: 0;
  border: 1px solid #fbd0d0;
  background-color: #fbd0d0;
  margin: 0 10px;
  transition: 0.5s;
}

.slick-dots li.slick-active button {
  border: 1px solid #ff5a2d;
}

/*  crs-left-box*/
.crs-left-box .card-img-top {
  width: 140px;
}

.crs-right-box h4 {
  word-wrap: break-word;
}

.v2_single-card {
  height: 100%;
}

/* animation items */
@-webkit-keyframes animateOne {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes animateOne {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/* custom-col-slide */

.custom-col-slide {
  width: 80%;
  margin-left: auto;
}

/* custom styles */
.mb-150 {
  margin-bottom: 150px;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  font-weight: 500;
}

.hero-bg {
  background: #fcf8f5 !important;
}

.orange-bg {
  background: #ff5a2d !important;
  color: #fff !important;
}

.sky-bg {
  background: #6a62ed !important;
  color: #fff !important;
}

/* text color */
.text-sky {
  color: #6a62ed !important;
}

.btn1 {
  padding: 8px 20px;
  border-radius: 30px;
  border: none;
  outline: none;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #ff5a2d !important;
}

/*  media query*/
@media (min-width: 1600px) and (max-width: 1919.98px) {
  .custom-col-slide {
    width: 88%;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1599.98px) {
  .vec-item1 {
    width: 22%;
  }

  .vec-item2 {
    width: 20%;
  }

  .v2_promote-section::after {
    display: none;
  }

  .pos-hanvec {
    right: 47px;
    width: 30px;
  }

  .custom-col-slide {
    width: 100%;
    margin-left: auto;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .vec-item1 {
    display: none;
  }

  .v2_promote-section::after {
    display: none;
  }

  .vec-item2 {
    display: none;
  }

  .v2_email-btn {
    padding: 8px 30px;
  }

  .pos-hanvec {
    right: 31%;
  }

  .custom-col-slide {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .vec-item1 {
    display: none;
  }

  .vec-item2 {
    display: none;
  }

  .v2_email-btn {
    padding: 8px 30px;
  }

  .v2_hero-title {
    font-size: 48px;
  }

  .v2_promote-section::after {
    display: none;
  }

  .pos-hanvec {
    display: none;
  }

  .v2_email-email {
    width: 100%;
  }

  .v2_email-btn {
    margin-top: 30px;
    width: 100%;
  }

  .custom-col-slide {
    width: 100%;
  }

  .crs-left-box .card-img-top {
    width: 90px;
  }
}

/* allthecourse-single-card */
.allthecourse-single-card {
  text-align: left;
  background-color: #fff;
  position: relative;
  border: 1px solid #e4e4e4;
  box-shadow: none;
  width: 230px;
  display: inline-block;
  vertical-align: top;
  margin: 10px;
}
.allthecourse-single-card img {
  width: 100%;
}

.details__name {
  display: block !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  -o-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: #1c1d1f;
  font-size: 13px;
  text-transform: capitalize;
  text-decoration: none;
}

.allthecourse-single-card .bottom-section {
  padding: 15px 20px 0px 15px;
  display: block;
}

.allthecourse-single-card .visit-name {
  color: #1c1d1f;
  display: block;
  font-size: 13px;
  text-transform: uppercase;
}

.allthecourse-single-card {
  color: #1c1d1f;
  display: block;
  font-size: 13px;
}
.allthecourse-single-card:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  background: #000000;
  opacity: 0.4;
}

/* card change */

.course_card {
  /* margin: 25px 10px; */
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  transition: 0.25s ease-in-out;
  border: 1px solid #e4e4e4;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
}
.course_card_img {
  max-height: 100%;
  max-width: 100%;
}
.course_card_img img {
  height: 250px;
  width: 100%;
  transition: 0.25s all;
}
.course_card_img img:hover {
  transform: translateY(-3%);
}
.course_card_content {
  padding: 0 16px 16px;
}
.course_card_content h3 {
  /* font-family: nunito sans; */
  font-family: 18px;
}
.course_card_content p {
  /* font-family: nunito sans; */
  text-align: justify;
}
.course_card_footer {
  margin: 0 16px 16px;
}
.course_card_footer a {
  text-decoration: none;
  text-transform: uppercase;
  color: #1c1d1f;
  font-size: 14px;
}
.course_card:hover {
  transform: scale(1.025);
  border-radius: 0.375rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
}
.course_card:hover .course_card_img img {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

/* progess bar */
.all-progress {
  height: 2px;
}

/* buttons */

/* Ujarak */
.button--ujarak {
  -webkit-transition: border-color 0.4s, color 0.4s;
  transition: border-color 0.4s, color 0.4s;
  border-color: #ff5a2d;
  color: #ff5a2d !important;
}
.button--ujarak::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #37474f; */
  background: #ff5a2d;
  z-index: -1;
  opacity: 0;
  -webkit-transform: scale3d(0.7, 1, 1);
  transform: scale3d(0.7, 1, 1);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--ujarak.button--round-s::before {
  border-radius: 2px;
}
.button--ujarak.button--inverted::before {
  background: #7986cb;
}
.button--ujarak,
.button--ujarak::before {
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--ujarak:hover {
  color: #fff !important;
  border-color: none;
}
.button--ujarak.button--inverted:hover {
  color: #37474f;
  border-color: #fff;
}
.button--ujarak:hover::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.title {
  color: #343a40;
  transition: 0.3s;
}
.title-gray {
  color: #948686;
}
.title:hover {
  color: #ff5a2d;
}

.progress-bar {
  background-color: #ff5a2d !important;
}
img.img-promo {
  width: 50px;
}
.table thead th {
  text-align: center;
}
.table td,
.table th {
  text-align: center;
}

/* instructor on single course */

.single_course_instructor {
  background: #fff;
  border-radius: 10px;
  padding: 10px 5px;
}

.single_course_instructor .card-header {
  border-bottom: 1px solid #007bff;
  border-radius: 10px;
}
.single_course_instructor .title {
  color: rgba(34, 41, 47, 1);
}
.single_course_instructor h4 {
  margin-right: 20px;
}

.single_course_instructor .link_item {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  background: #c9dae1;
  color: #fff;
  text-align: center;
  display: block;
  transition: 0.3s;
  margin-right: 10px;
}
.single_course_instructor .link_item:hover {
  background: #007bff;
}
.single_course_instructor .instructor_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #007bff;
  margin-right: 30px;
}

.img-w-130 {
  width: 130px;
  border-radius: 5px;
}

.single-course-tab {
  /* border: 1px solid #007bff; */
  display: flex;
  justify-content: center;
  width: 100%;
}

.single-course-tab .nav-link.active {
  background-color: #007bff;
  color: #fff;
}
.single-course-tab .nav-link {
  border: 1px solid #007bff;
  border-radius: 5px;
  transition: 0.3s;
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  outline: none;
}
.single-course-tab .nav-link:focus {
  outline: none;
}

/* added by sujan - 19/11/2022 - student support page */

.no-support-text h2 {
  font-size: 20px;
  font-weight: 700;
}

.no-support-text span {
  color: white;
  font-size: 25px;
  background-color: #e70b66;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 700;
}

.notice-title .support-days {
  font-size: 18px;
}